import * as _angular from 'angular';
import * as _angularLoad from 'angular-load';
import * as _ngCookies from 'angular-cookies';

// --- globals ---
import React from 'react';
import * as ReactDOM from 'react-dom'; // ReactDom.createPortal can only be imported from index.js distributable
import * as ReactDOMClient from 'react-dom/client'; // ReactDom.client can only be imported from the client bundle distributable
import * as ReactRedux from 'react-redux';
import * as RTK from '@reduxjs/toolkit';
import * as RTKQ from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import JSData from 'js-data';
import ImgixClient from '@imgix/js-core';
import _ from 'lodash';
import imgixParameters from 'image-api-spec/dist/parameters.json';
import URI from 'urijs';
import scrollIntoView from 'scroll-into-view-if-needed';
import LRUCache from 'serialized-lru-cache';
import Mailcheck from 'mailcheck';
import Fuse from 'fuse.js';
import { default as DSHttpAdapter } from 'js-data-http';
import { saveAs } from 'file-saver';
import md5 from 'js-md5';
import Hls from 'hls.js';

export const angular = _angular;
export const angularLoad = _angularLoad;
export const ngCookies = _ngCookies;

window.axios = axios;
window.React = React;
window.ReactDOM = ReactDOM;
window.ReactDOMClient = ReactDOMClient;
window.ReactRedux = ReactRedux;
window.RTK = RTK;
window.RTKQ = RTKQ;
window.ImgixClient = ImgixClient;
window._ = _;
window.imgixParameters = imgixParameters;
window.URI = URI;
window.scrollIntoView = scrollIntoView;
window.LRUCache = LRUCache;
window.Mailcheck = Mailcheck;
window.Fuse = Fuse;
window.JSData = JSData;
window.DSHttpAdapter = DSHttpAdapter;
window.saveAs = saveAs;
window.md5 = md5;
window.Hls = Hls;

// --- Web Shared ---
import * as _countryRegionData from 'country-region-data/dist/data-umd';
import * as _ngAnimate from 'angular-animate';
import * as _ngSanitize from 'angular-sanitize';
import * as _ngStorage from 'ngstorage';
import * as _uiValidate from 'angular-ui-validate';
import * as _uiRouter from 'angular-ui-router';

export const ngAnimate = _ngAnimate;
export const countryRegionData = _countryRegionData.default;
export const ngSanitize = _ngSanitize;
export const ngStorage = _ngStorage;
export const uiValidate = _uiValidate;
export const uiRouter = _uiRouter;

window.countryRegionData = countryRegionData;
